<template>
  <div class="tecnews-page">
    <Nav />
    <div class="tecnews-wrap">
      <div class="news-top">
        <h1>
          TecNews
          <br />浩亭技术杂志
        </h1>
        <svg
          class="arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
            fill="white"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div class="swiper-box">
        <div class="card-wrap" v-if="blockList && blockList.length">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide
              class="card"
              v-for="(item, index) in swiperList"
              :key="index"
              ><img :src="item.cover_image" alt="" />
              <div class="bottom">
                <div class="title">{{ item.title }}</div>
                <div
                  class="download"
                  :data-index="index"
                  data-type="download"
                ></div></div
            ></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <div class="block-wrap">
        <div v-for="item in cardList" :key="item.title" class="block-box">
          <div class="title">{{ item.title }}</div>
          <div class="download" @click="openDownLoad(item)"></div>
        </div>
      </div>
      <van-popup
        v-model="donwLinkShow"
        :close-on-click-overlay="false"
        :closeable="true"
        @close="closePopup"
      >

        <div class="pdf-main" v-if="pdfShow">
            <pdf
              :src="pdfSrc"
              :page="currentPage"
              @progress="loadedRatio = $event"
              @loaded="loadPdfHandler"
              @num-pages="pageCount = $event"
              @page-loaded="currentPage = $event"
              ref="pdf-wrapper"
              class="pdf"
            ></pdf>
            <van-loading size="24px" v-if="pdfLoad">加载中...</van-loading>
            <ul class="footers">
              <li
                :class="{ select: idx == 2 }"
                @touchstart="idx = 2"
                @touchend="idx = -1"
                @click="changePdfPage(0)"
              >
                <p class="up-p">上一页</p>
              </li>
              <li
                :class="{ select: idx == 3 }"
                @touchstart="idx = 3"
                @touchend="idx = -1"
                @click="changePdfPage(1)"
              >
                <p class="down-p">下一页</p>
              </li>
              <li>
                <p class="number">当前第{{ currentPage }}页/共{{ pageCount }}页</p>
              </li>
            </ul>
          </div>
          <div class="downlink-box" v-else>
          <p class="title">请复制下载链接到浏览器</p>
          <p class="cont">{{ donwLink }}</p>
          <copy :textToCopy="donwLink"></copy>
          <p @click="openPDF" class="preview-pdf">在线预览</p>
        
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper"; // 高级版本
import "swiper/css/swiper.css"; // 5.0版本
import dayjs from "dayjs";
import request from "../../utils/request";
import { Popup, Loading } from "vant";
import Nav from "../../component/nav.vue";
import Copy from "../../component/copy.vue";
import pdf from "vue-pdf";

function convertPxToResponsivePx(pxValue) {
  const designWidth = 375;
  const screenWidth = window.innerWidth;
  const ratio = screenWidth / designWidth;
  return pxValue * ratio;
}
export default {
  name: "TECNEWS",
  components: {
    Nav,
    Swiper,
    SwiperSlide,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
    Copy,
    pdf,
    // swiper,
    // swiperSlide,
  },
  computed: {
    swiperList() {
      return this.blockList.slice(0, 3);
    },
    cardList() {
      return this.blockList;
    },
  },
  data() {
    return {
      pdfShow: false,
      pdfSrc: "path/to/your/pdf/file.pdf",
      currentPage: 1, // 当前页码
      pageCount: 0, // 总页码
      scale: 100,
      idx: -1,
      loadedRatio: 0,
      blockList: [],
      donwLinkShow: false,
      donwLink: "",
      swiperOption: {
        initialSlide: 0,
        loop: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0,
          stretch: convertPxToResponsivePx(72.6),
          depth: convertPxToResponsivePx(90),
          modifier: 3,
          slideShadows: true,
        },
        paginationClickable: true,
        watchActiveIndex: true,
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          click: (e) => {
            this.handSwiper(e);
            e.stopPropagation();
            e.preventDefault();
            return false;
          },
        },
      },
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY.MM.DD");
    },
    // 获取新闻列表数据
    fetchList() {
      request
        .get("/magazine/list", {
          page_size: 10,
          page_num: 1,
        }) // 假设接口地址为 /news
        .then((response) => {
          this.blockList = response.data; // 将接口返回的数据赋值给newsList
        })
        .catch((error) => {
          console.error("Error fetching news list:", error);
        });
    },
    handSwiper(e) {
      let { index, type } = e.target.dataset;
      console.log(index, type, e);
      const item = { ...this.swiperList[index] };
      if (type == "download") {
        this.openDownLoad(item);
      }
    },
    loadPdfHandler() {
      this.pdfLoad = false
      this.currentPage = 1; // 加载的时候先加载第一页
    },
    openDownLoad(item) {
      this.donwLinkShow = true;
      this.donwLink = item.download_link;
      this.pdfSrc = item.download_link;
    },
    openPDF() {
      pdf.createLoadingTask(this.pdfSrc);
      this.pdfShow = true;
      this.pdfLoad = true
    },
    changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
      console.log(this.currentPage);
      console.log(this.pageCount);
    },
    goBack() {
      this.$router.go(-1);
    },
    // //放大
    // scaleD() {
    //   this.scale += 5;
    //   this.$refs.wrapper.$el.style.width = parseInt(this.scale) + "%";
    // },

    // //缩小
    // scaleX() {
    //   if (this.scale == 100) {
    //     return;
    //   }
    //   this.scale += -5;
    //   this.$refs.wrapper.$el.style.width = parseInt(this.scale) + "%";
    // },
    closePopup() {
      this.pdfShow = false
    }
  },
  created() {
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchList();
  },
};
</script>
<style lang="less" scoped>
.tecnews-page {
  width: 100%;
  min-height: 100vh;
  background: #cdd7de;
  .title {
    width: 255px;
    color: #212027;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
  }
  .download {
    width: 32px;
    height: 32px;
    background: url("../../image/download-icon.png") no-repeat center center /
      contain;
  }
  .tecnews-wrap {
    position: relative;
    padding-top: 96px;
    width: 100%;
    .news-top {
      padding: 0 32px;
      h1 {
        margin-bottom: 8px;
        color: #212027;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 48px */
      }
      .arrow {
        margin-bottom: 32px;
      }
    }
    .swiper-box {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 24px;
      margin-bottom: 40px;
      width: 100%;
      height: 428px;
      .swiper {
        height: 100%;
        width: 100%;
        .swiper-slide-next,
        .swiper-slide-prev {
          opacity: 0.8;
        }
      }
    }
    .card-wrap {
      width: 100%;
      height: 100%;
    }
    .card {
      border-radius: 24px;
      width: 279px;
      height: 389px;
      background: #fff;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 313px;
        object-fit: cover;
      }
      .bottom {
        display: flex;
        background: #fff;
        padding: 16px;
      }
    }
    .block-wrap {
      padding: 0 24px 100px 24px;
    }
    .block-box {
      margin-bottom: 12px;
      border-radius: 12px;
      background: #fff;
      display: flex;
      padding: 16px;
      align-items: center;
      justify-content: space-between;
    }
  }
  .preview-pdf{
    padding: 16px 12px;
    width: 100%;
    border-radius: 50px;
    border: none;
    background: #2196F3;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
  .pdf-main{
    width: 90vw;
    .footers{
      padding: 10px 0 10px 10px;
      display: flex;
      font-size: 14px;
      line-height: 20px;
      li{
        margin-right: 10px;
        padding: 10px 5px ;
        background: #2196F3;
        border-radius: 5px;
      }
    }
    .van-loading{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
<style lang="less">

</style>
