<template>
  <div class="copy-box">
    <!-- <input type="text" v-model="textToCopy" placeholder="输入要复制的文本" /> -->
    <button ref="copyButton" @click="copyText" class="copy-btn">
      一键复制
    </button>
    <p v-if="copied" class="copied-message">已复制到剪贴板</p>
  </div>
</template>

<script>
import Clipboard from "clipboard";

export default {
  props: {
    textToCopy: {
      type: String,
      default: "默认文本",
    },
  },
  data() {
    return {
      //   textToCopy: "",
      clipboard: null,
      copied: false,
    };
  },
  methods: {
    copyText() {
      this.clipboard = new Clipboard(this.$refs.copyButton, {
        text: () => this.textToCopy,
      });
      this.clipboard.on("success", (e) => {
        this.copied = true;
        // 延迟一段时间后隐藏复制成功提示
        setTimeout(() => {
          this.copied = false;
        }, 3000);
        // 清除 clipboard 实例，避免内存泄漏
        this.clipboard.destroy();
        this.clipboard = null;
        e.clearSelection();
      });
      this.clipboard.on("error", (e) => {
        console.error("复制失败:", e);
        this.copied = false;
        // 清除 clipboard 实例，避免内存泄漏
        this.clipboard.destroy();
        this.clipboard = null;
      });
    },
  },
  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },
};
</script>
<style lang="less" scoped>
.copy-box {
  padding: 10px 0;
  font-size: 16px;
  .copy-btn {
    margin-bottom: 10px;
    padding: 16px 12px;
    width: 100%;
    border-radius: 50px;
    border: none;
    background: #fc0;
    color: #fff;
  }
  .copied-message {
    width: 100%;
    text-align: center;
    color: green;
  }
}
</style>
